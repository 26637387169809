.root {
  background-color: white;
}
.root p {
  padding: 24px;
}
.cards {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  margin-bottom: 5%;
}

.headertag {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 32px;
}

.headertag h2 {
  font-family: "Great Vibes", cursive;
  font-size: 102px;
}

@media only screen and (max-width: 600px) {
  .imagecontainerr img {
    margin-top: 30%;
    width: 100%;
    height: 525px;
  }
  .cards {
    flex-direction: column;
    margin-top: 10%;
  }
  .headertag {
    display: none;
  }
}
.cards p {
  padding: 6px;
}
.cards h1 {
  padding: 6px;
  text-align: center;
  white-space: nowrap;
}

@media only screen and (min-width: 2040px) {
  .imagecontainerr img {
    width: 25%;
    height: 825px;
  }
}

.socialmedia {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.socialmedia a {
  margin-bottom: 25px;
  text-transform: unset;
}
