body {
  text-align: center;
}
.root {
  margin: auto;
  padding: 10px;
  margin-top: 5%;
}
@media only screen and (max-width: 600px) {
  .root h1 {
    margin-top: 10%;
  }
}
.root img {
  border-radius: 28px;
}
.root h1 {
  font-size: 42px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  padding: 32px;
}
@media only screen and (max-width: 600px) {
  .root h1 {
    font-size: 14px;
  }
}
.firstcontainer {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  padding: 22px;
}

.firstcontainer a {
  margin-left: auto;
  margin-right: auto;
}
.eurogroom {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
}

.eurogroom a {
  margin-left: auto;
  margin-right: auto;
}

.ram {
  padding: 22px;
}

@media only screen and (max-width: 1350px) {
  .firstcontainer {
    display: flex;
    flex-direction: column;
  }
}
