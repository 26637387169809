* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 75px;
  top: 0;
  width: 100%;
  background-color: white;
  color: black;
  position: fixed;
}
.logo {
  font-size: 32px;
  cursor: pointer;
  font-style: italic;
  font-family: "Great Vibes", cursive;
}
.nav-links {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  width: 75%;
}

.home,
.puppynews,
.mydogs,
.links {
  text-decoration: none;
  color: black;
  font-size: 20px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
}

.mobile-menu-icon {
  display: none;
}

@media screen and (max-width: 780px) {
  .logo {
    display: flex;
    position: absolute;
    top: 15px;
    left: 35px;
  }
  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    background-color: white;
    left: 0;
    top: 75px;
    transition: all 0.5s ease-out;
    width: 100%;
  }
  .home,
  .puppynews,
  .mydogs,
  .links {
    color: black;
    text-align: center;
    transition: all 0.5s ease;
  }
  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    color: black;
    background-color: white;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
  }
}
