p {
  padding: 6px;
}

.containerr {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 42px;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .containerr {
    flex-direction: column;
    margin-top: 15%;
  }
  .imagecontainer img {
    width: 355px;
    height: 355px;
  }
}
.imagecontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imagecontainer img {
  margin-bottom: 5%;
}

.imagecontainer label {
  font-size: 16px;
  font-weight: bold;
}
