.container {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  flex-direction: column;
  margin-top: 100px;
}

/* @media only screen and (max-width: 1080px) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
} */
