.card-container {
  overflow: hidden;
  box-shadow: 0px 0px 15px -5px;
  transition: 0.3s;
  animation: ease-in;
  margin-bottom: 25px;
  width: 50%;
  height: 50%;
  margin-top: 25px;
}
.card-container:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 15px 0px;
}

.image-container img {
  overflow: hidden;
  width: 355px;
  height: 400px;
}

@media only screen and (max-width: 1024px) {
  .image-container img {
    width: 80%;
    height: 80%;
  }
  .card-container {
    width: 100%;
  }
}

.card-content {
  margin: 1rem;
  margin-top: 0.5rem;
}

.h3,
p {
  margin: 0;
  padding: 0;
}

.card-.title {
  margin-bottom: 0.5rem;
}

a {
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  font-weight: bold;
}
